var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-layout-wrapper", attrs: { id: "userLayout" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "desc" }, [
            _c("img", {
              staticClass: "logo_login",
              attrs: { src: _vm.logoUrl, alt: "" }
            })
          ]),
          _c("div", { staticClass: "header" }, [
            _c("a", { attrs: { href: "/" } }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.siteTitle) + "超级后台")
              ])
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "main" },
          [
            _c(
              "el-form",
              {
                ref: "loginForm",
                attrs: { model: _vm.loginForm, rules: _vm.rules },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.submitForm($event)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "username" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入用户名" },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "el-input__icon el-icon-user",
                          attrs: { slot: "suffix" },
                          slot: "suffix"
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          type: _vm.lock === "lock" ? "password" : "text",
                          placeholder: "请输入密码"
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password"
                        }
                      },
                      [
                        _c("i", {
                          class: "el-input__icon el-icon-" + _vm.lock,
                          attrs: { slot: "suffix" },
                          on: { click: _vm.changeLock },
                          slot: "suffix"
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticStyle: { position: "relative" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "60%" },
                      attrs: { name: "logVerify", placeholder: "请输入验证码" },
                      model: {
                        value: _vm.loginForm.captcha,
                        callback: function($$v) {
                          _vm.$set(_vm.loginForm, "captcha", $$v)
                        },
                        expression: "loginForm.captcha"
                      }
                    }),
                    _c("div", { staticClass: "vPic" }, [
                      _vm.picPath
                        ? _c("img", {
                            attrs: {
                              src: _vm.picPath,
                              width: "100%",
                              height: "100%",
                              alt: "请输入验证码"
                            },
                            on: {
                              click: function($event) {
                                return _vm.loginVefify()
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { type: "primary" },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v("登 录")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }